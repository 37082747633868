import React, {useState, useEffect} from "react";
import {useDropzone} from 'react-dropzone';
import fileDownload from 'js-file-download';
import axios from 'axios';
import {ToastContainer, toast} from 'react-toastify';
import {Line} from 'rc-progress';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import 'react-toastify/dist/ReactToastify.css';
import './FilePicker.css';
import downloadIcon from '../assets/image/download.png';

export function FilePicker(props) {
    const [downloadFilename, setDownloadFilename] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [downloadedFiles, setDownloadedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isFileLogHidden, showFileLog] = useState(true);
    const [progressPercent, setProgressPercent] = useState(0);
    const [intervalId, setIntervalID] = useState(null);
    const [endIntervalId, setEndIntervalID] = useState(null);
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

    useEffect(() => {
        axios.get('/status', { params: { email: props.user.email } }).then(resp => {
            if (resp.status === 200 && resp.data) {
                setUploadedFiles(resp.data.uploaded || []);
                setDownloadedFiles(resp.data.downloaded || []);
            }
        })
    }, [])

    // const files = acceptedFiles.map(file => (
    //     <li key={file.path}>
    //         {file.path} - {file.size} bytes
    //     </li>
    // ));

    useEffect(() => {
        if (loading) {
            if (intervalId) return;
            const id = setInterval(() => setProgressPercent(progressPercent =>
                progressPercent < 99 ? progressPercent + 0.8 : progressPercent), 1000);
            console.log(id);
            setIntervalID(id);
        } else {
            if (intervalId) {
                clearInterval(intervalId);
                setIntervalID(null);
                if (!endIntervalId && progressPercent !== 0) {
                    const percentIncreaseUnit = (100 - progressPercent) / 10;
                    const id = setInterval(() => setProgressPercent(progressPercent =>
                    progressPercent < 99 ? progressPercent + percentIncreaseUnit : 99), 100);
                    setEndIntervalID(id);
                }
            }
        }
    }, [loading, intervalId, progressPercent, endIntervalId])

    useEffect(() => {
        if (progressPercent >= 99) {
            if (intervalId) {
                clearInterval(intervalId);
                setIntervalID(null);
            }
            if (endIntervalId) {
                clearInterval(endIntervalId);
                setEndIntervalID(null);
            }
            if (loading) {
                notify('info', 'Please wait your chess avatar is being created, this can take some minutes depending on number of games');
            } else {
                setTimeout(() => setProgressPercent(0), 1000);
            }
        }
    }, [progressPercent, loading])

    const fileUpload = e => {
        setDownloadFilename(null);
        setLoading(true);
        const data = new FormData();
        data.append('file', acceptedFiles[0]);
        data.append('email', props.user.email)
        axios.post("upload", data, { // receive two parameter endpoint url ,form data
        })
            .then(res => {
                if (res.data.filename) {
                    setDownloadFilename(res.data.filename);
                    setUploadedFiles(res.data.fileStatus.uploaded);
                    setDownloadedFiles(res.data.fileStatus.downloaded)
                    notify('success', 'Your chess avatar has been created, please download it.');
                } else {
                    notify('failed', 'Upload failed');
                    setProgressPercent(0);
                }
            })
            .catch(e => {
                notify('failed', 'Upload failed');
                setProgressPercent(0);
            })
            .finally(() => setLoading(false));
    }

    const onDownload = filename => {
        function getResult(isShareResult = false) {
            axios.get('download', {
                params: {
                    filename: filename,
                    email: props.user.email,
                    isShareResult,
                },
                responseType: 'blob',
            }).then(resp =>
                fileDownload(resp.data, filename));
        }
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Do you want to share the result with others?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => getResult(true)
              },
              {
                label: 'No',
                onClick: () => getResult()
              }
            ]
        });
    }

    const notify = (type, message) => {
        const config = {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        };
        if (type === 'success') toast.success(message, config);
        if (type === 'failed') toast.error(message, config);
        if (type === 'info') toast.info(message, config);
    }

    return (
        <>
            {
                (intervalId || endIntervalId) && progressPercent > 0 && <>
                    <div className="opacity-custom"/>
                    <div className="progress-percent">{Math.floor(progressPercent)} %</div>
                    <Line percent={Math.floor(progressPercent)}
                          style={{position: 'fixed', width: '50%', top: '50%', left: '25%', zIndex: 100}}
                          strokeWidth={2}
                          trailWidth={2}/>
                </>
            }
            <section className="file-pickers">
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    {acceptedFiles.length > 0 ? <p>{acceptedFiles[0].name}</p> :
                        <p>Click and select your games PGN file or drag 'n drop it</p>}
                </div>
                {/*<aside>*/}
                {/*    <h4>Files</h4>*/}
                {/*    <ul>{files}</ul>*/}
                {/*</aside>*/}
                <div className="row-cols-md-12 row">
                    <div className="col-3"/>
                    <button
                        type="button"
                        className="m-2 btn btn-primary btn-sm col-3 py-3"
                        disabled={acceptedFiles.length === 0 || loading}
                        onClick={fileUpload}
                    >
                        UPLOAD
                    </button>
                    <button
                        type="button"
                        className="m-2 btn btn-primary btn-sm col-3 py-3"
                        disabled={!downloadFilename || loading}
                        onClick={() => onDownload(downloadFilename)}
                    >
                        DOWNLOAD
                    </button>
                    <div className="col-3"/>
                </div>
                {
                    (downloadedFiles.length + uploadedFiles.length) > 0 &&
                    <div className="card align-self-center col-7 ml-auto mr-auto row">
                        <button className="btn btn-primary btn-sm" onClick={() => showFileLog(!isFileLogHidden)}>
                            {isFileLogHidden ? 'Show' : 'Hide'} File Logs
                        </button>
                        {
                            !isFileLogHidden &&
                            <div className="card-body row">
                                <div className="col-6">
                                    <h5>Uploaded Files</h5>
                                    <ul className="list-group list-height">
                                        {
                                            uploadedFiles.map((file, idx) =>
                                                <li key={`uploaded-${idx}`} className="list-group-item">{file}</li>
                                            )
                                        }
                                    </ul>
                                </div>
                                <div className="col-6">
                                    <h5>Downloaded Files</h5>
                                    <ul className="list-group list-height">
                                        {
                                            downloadedFiles.map((file, idx) =>
                                                <li
                                                    key={`downloaded-${idx}`}
                                                    className="list-group-item"
                                                >
                                                    <div>{file}</div>
                                                    <img
                                                        src={downloadIcon}
                                                        className="download"
                                                        onClick={() => onDownload(file)}
                                                    />
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                }
                <ToastContainer/>
            </section>
        </>

    );
}
