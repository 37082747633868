import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
import classNames from "classnames";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { sendPasswordReset } from "../actions/auth";
import {setMessage} from "../actions/message";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const ForgotPassword = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn, passwordResetStatus } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  const dispatch = useDispatch();

  const onChangeUserEmail = (e) => {
    const email = e.target.value;
    setUserEmail(email);
  };

  const handleRequest = (e) => {
    e.preventDefault();

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      setLoading(true);
      dispatch(sendPasswordReset(userEmail))
        .then(resp => setLoading(false))
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className="col-md-12 h-100 d-flex align-items-center justify-content-center">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleRequest} ref={form}>
          <div className="form-group">
            Enter your user account's verified email address and we will send you a password reset link.
          </div>
          <div className="form-group">
            <label htmlFor="username">User Email</label>
            <Input
              type="text"
              className="form-control"
              name="userEmail"
              value={userEmail}
              onChange={onChangeUserEmail}
              validations={[required]}
            />
          </div>

          <div className="form-group">
            {loading ? (
                <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                      <BeatLoader loading={loading} size={10} margin={4} color="#0069d9"/>
                    </div>
              ) : <button className="btn btn-primary btn-block" disabled={loading}><span>Send password reset email</span></button>
            }
          </div>

          { (passwordResetStatus === 'sent' || message) && (
            <div className="form-group">
              <div className={
                classNames("alert", {
                  "alert-danger": message,
                  "alert-success": passwordResetStatus === 'sent'
                })
              } role="alert">
                { passwordResetStatus === 'sent' ? 'A password reset link has been sent to your email successfully.' : message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        <div>Already have a account? Please <a href='/login'>Login</a></div>
      </div>
    </div>
  );
};

export default ForgotPassword;
