import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux"
import UserService from "../services/user.service";
import {Redirect} from "react-router-dom";
import {FilePicker} from '../FilePicker';
import { logout } from "../actions/auth";
import './index.css';

const Home = () => {
    const [content, setContent] = useState("");

    const {user: currentUser} = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    if (!currentUser) {
        return <Redirect to="/login"/>;
    }

    const onLogout = e => {
        localStorage.removeItem("user");
        dispatch(logout());
    }

    return (
        <div>
            <div className="logout">
                <a onClick={onLogout} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textDecoration: 'none', color: '#2196f3'}}>
                    <p style={{ fontSize: 15}}>&#8617;</p>
                    <p style={{ fontSize: 20, marginLeft: 5 }}>Logout</p>
                </a>
                <a href="/results" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textDecoration: 'none', marginLeft: 10}}>
                    <p style={{ fontSize: 15}}>&#11088;</p>
                    <p style={{ fontSize: 20}}>Results</p>
                </a>
            </div>            
            <header className="header bg-success">
                <h2>File Manager</h2>
            </header>
            <div className="main-div">
            <FilePicker user={currentUser} />
            </div>

        </div>
    );
};

export default Home;
