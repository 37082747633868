import React, { useEffect, useState } from "react";
import { Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";
import fileDownload from 'js-file-download';
import axios from 'axios';

const Results = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (currentUser) {
      axios.post('results')
        .then(resp => {
          console.log('results', resp.data.results);
          if (resp.data.results) {
            setResults(resp.data.results);
          }
        })
        .catch(err => console.error(err));
    }    
  }, [currentUser])

  const downloadResult = (file_name) => {
    axios.get(`results/${file_name}`, {
      responseType: 'blob',
    }).then(resp => {
      fileDownload(resp.data, file_name);      
    });
  }

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  return (
    <div>
      <header className="header bg-success">
          <h2>SHARED RESULTS</h2>
      </header>
      <a href="/" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textDecoration: 'none'}}>
        <p style={{ fontSize: 40}}>&larr;</p>
        <p style={{ fontSize: 25}}>Back</p>
      </a>
      <div className="results-content">
        {results.map(file_name => (
          <div onClick={() => downloadResult(file_name)}>
            {file_name}
          </div>
        ))}
      </div>
  </div>
  );
};

export default Results;
