import axios from "axios";

// const API_URL = "http://localhost:8080/api/auth/";
const API_URL = "";

const register = (username, email, password) => {
  return axios.post(API_URL + "register", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
        return response.data;
      } else {
        return Promise.reject({message: response.data.message});
      }
    });
};

const sendPasswordReset = (userEmail) => {
  return axios
    .post(API_URL + "password-reset", {
      userEmail,
    })
    .then((response) => {
      if (response.data.status === 'success') {
        return response.data;
      } else {
        return Promise.reject({message: response.data.message});
      }
    });
};

const changePassword = params => {
  return axios
    .post(API_URL + "change-password", params)
    .then((response) => {
      if (response.data.status === 'success') {
        return response.data;
      } else {
        return Promise.reject({message: response.data.message});
      }
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  register,
  login,
  logout,
  sendPasswordReset,
  changePassword
};
